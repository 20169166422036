/*------------------------------------------------*/
/* LIBRARIES
/*------------------------------------------------*/
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import _get from 'lodash/get';
/*------------------------------------------------*/
/* INTERNAL DEPENDENCIES
/*------------------------------------------------*/
// Components
import Layout from '../components/layout';
import SEO from '../components/seo';
import Container from '../components/Container';
import Image from '../components/Image';
import SignUpModal from '../components/SignUpModal';
import Testimonials from '../components/Testimonials';
import TrustedLogos from '../components/TrustedLogos';
import {
  BodyTextBig,
  GlobalHero,
  HeroHeading,
  SectionTitle,
} from '../components/Global';
import SimplifyTeamWork from '../components/SimplifyTeamWork';
import WorkManagementPlatformSection from '../components/Home/WorkManagementPlatformSection';
import WhatMattersMostSection from '../components/Home/WhatMattersMostSection';
import EmpowerYourTeamSection from '../components/Home/EmpowerYourTeamSection';
import WorkToSuitSection from '../components/Home/WorkToSuitSection';
import BlogSection from '../components/Blog/BlogSection';
// Styles
import MediaBreakDown from '../styles/MediaBreakDown';


const Hero = styled(GlobalHero)`
  .hero-text {
    max-width: 474px;
    margin: 0 30px 0 0;

    h1 {
      max-width: 395px;
    }
  }
  .hero-img {
    width: 55%;
  }
  ${MediaBreakDown.lg`
    .hero-text {
      margin: auto;

      a, button {
        display: none;
      }
    }
    .hero-img {
      width: 100%;

      a, button {
        width: 150px;
      }
    }
  `}
`;

const CenteredSection = styled.div`
  padding: 100px 0 0;
  text-align: center;
  ${MediaBreakDown.sm`
    padding: 60px 0 0;
  `}
`;

const AboveFooter = styled.div`
  position: relative;
  height: 530px;

  .first-image {
    position: absolute;
    left: 15%;
    width: 30%;
    max-width: 580px;
    top: 100px;
  }
  .second-image {
    width: 40%;
    max-width: 642px;
    position: relative;
    float: right;
  }
  ${MediaBreakDown.lg`
    .first-image {
      left: 0;
      top: 70px;
      width: 35%;
    }
    .second-image {
      width: 50%;
    }
  `}
  ${MediaBreakDown.sm`
    text-align: center;
    height: 340px;
    .first-image {
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 309px;
      height: 310px;
    }
    .second-image {
      display: none;
    }
  `}
`;


const IndexPage = (props) => {
  const {
    location, data,
  } = props;
  const solutionItems = _get(data, 'allContentfulSolutionPage.nodes', []);
  const blogArticles = _get(data, 'allContentfulBlogPost.edges', [])
    .map(itemEdge => (_get(itemEdge, 'node')));


  return (
    <Layout location={location}>
      <SEO
        title="Workast | Work Management Platform for Businesses - Try it Today!"
        description="Maximize your business' efficiency with Workast - the ultimate work management platform. Streamline your collaboration and accomplish more in less time. Get started today and see how Workast can help you."
        imageName="hero_image.png"
      />
      <Container>
        <Hero>
          <div className="hero-text">
            <HeroHeading>
              Make teamwork a breeze with Workast
            </HeroHeading>
            <BodyTextBig mt="30px" mb="45px">
              Delegate tasks, manage projects, run meetings, streamline processes,
              and gain visibility of what your team is working on with the best
              Work Management Platform for your business.
            </BodyTextBig>
            <SignUpModal buttonText="Try it for free" />
          </div>
          <div className="hero-img">
            <Image name="hero_image.png" alt="Make teamwork a breeze with Workast" />
            <SignUpModal buttonText="Try it for free" />
          </div>
        </Hero>
      </Container>
      <WorkManagementPlatformSection
        items={solutionItems}
      />
      <TrustedLogos />
      <WhatMattersMostSection />
      <EmpowerYourTeamSection />
      <WorkToSuitSection />
      
      <CenteredSection>
        <SectionTitle mb="10px">
          Why teams love Workast
        </SectionTitle>
        <Testimonials data={data.allContentfulTestimonial.edges} />
      </CenteredSection>

      {/* BLOG ARTICLES */}
      {blogArticles.length > 0 && (
        <Container style={{ paddingTop: '80px' }}>
          <BlogSection
            title="Fresh from our blog"
            items={blogArticles}
            useCarousel
          />
        </Container>
      )}
      {/* CTA */}
      <SimplifyTeamWork style={{ padding: '80px 0 60px 0' }} />
      <AboveFooter>
        <div className="first-image">
          <Image name="two-peoples.png" alt="two peoples" />
        </div>
        <div className="second-image">
          <Image name="one-person.png" alt="one people" />
        </div>
      </AboveFooter>
    </Layout>
  );
};


/*------------------------------------------------*/
/* PROP TYPES
/*------------------------------------------------*/
IndexPage.propTypes = {
  data: PropTypes.shape({
    allContentfulTestimonial: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            author: PropTypes.string.isRequired,
            role: PropTypes.string.isRequired,
            description: PropTypes.shape({
              description: PropTypes.string.isRequired
            }).isRequired,
            image: PropTypes.shape({
              fluid: PropTypes.shape({
                aspectRatio: PropTypes.number,
                base64: PropTypes.string,
                sizes: PropTypes.string,
                src: PropTypes.string,
                srcSet: PropTypes.string,
                srcSetWebp: PropTypes.string,
                srcWebp: PropTypes.string,
              }),
            }),
          }).isRequired,
        })
      ).isRequired,
    }).isRequired,
  }).isRequired,
  location: PropTypes.shape({
    host: PropTypes.string,
    hostname: PropTypes.string,
    href: PropTypes.string,
    key: PropTypes.string,
    origin: PropTypes.string,
    pathname: PropTypes.string,
    port: PropTypes.string,
  }).isRequired,
};

/*------------------------------------------------*/
/* EXPORTS
/*------------------------------------------------*/
export default IndexPage;

export const pageQuery = graphql`
  query IndexPageQuery {
    allContentfulTestimonial {
      edges {
        node {
          author
          role
          description {
            description
          }
          image {
            gatsbyImageData(width:150)
          }
        }
      }
    }
    allContentfulSolutionPage {
      nodes {
        slug
        icon {
          title
          url
        }
      }
    }
    allContentfulBlogPost(
      sort: { fields: [publishDate], order: DESC }
      limit: 6
    ) {
      edges {
        node {
          id
          title
          slug
          featuredImage {
            description
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`;
