/*------------------------------------------------*/
/* LIBRARIES
/*------------------------------------------------*/
import React from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import _toNumber from 'lodash/toNumber';
/*------------------------------------------------*/
/* INTERNAL DEPENDENCIES
/*------------------------------------------------*/
// Components
import HeaderItem from './HeaderItem';
// Style
import SideTabGroupWrapper from './index.style';


const SideTabGroup = (props) => {
  /* PROPS ------------------------------------------------*/
  const {
    title,
    items,
  } = props;

  /* USE STATE ------------------------------------------------*/
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  /* METHODS ------------------------------------------------*/
  const handleItemClick = React.useCallback((index) => {
    if (index >= 0) {
      setSelectedIndex(_toNumber(index));
    }
  }, []);


  /* RENDER ------------------------------------------------*/
  if (items.length === 0) {
    return null;
  }

  return (
    <SideTabGroupWrapper>
      <div className="tab-header-wrapper">
        <h3 className="tab-header-title">{title}</h3>
        <div className="buttons-wrapper">
          {items.map((item, itemIndex) => (
            <HeaderItem
              key={_get(item, 'id', '')}
              index={itemIndex}
              selected={itemIndex === selectedIndex}
              title={_get(item, 'title', '')}
              onClick={handleItemClick}
            />
          ))}
        </div>
      </div>
      <div className="tab-content-wrapper">
        {items[selectedIndex].content}
      </div>
    </SideTabGroupWrapper>
  );
};


/*------------------------------------------------*/
/* PROP TYPES
/*------------------------------------------------*/
SideTabGroup.defaultProps = {
  items: [],
};
SideTabGroup.propTypes = {
  // Required props:
  title: PropTypes.string.isRequired,
  // Optional props:
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
      ]),
      content: PropTypes.element,
    })
  ),
};

/*------------------------------------------------*/
/* EXPORTS
/*------------------------------------------------*/
export default SideTabGroup;
