/*------------------------------------------------*/
/* LIBRARIES
/*------------------------------------------------*/
import React from 'react';
/*------------------------------------------------*/
/* INTERNAL DEPENDENCIES
/*------------------------------------------------*/
// Components
import {
  SectionTitle,
} from '../../Global';
import Container from '../../Container';
import TabGroup from '../../TabGroup';
import TabContent from './TabContent';
// Style
import WhatMattersMostSectionWrapper from './index.style';
// Constans
import constants from '../../../constants';


const tabItems = [{
  title: 'Projects & Tasks',
  content: (
    <TabContent
      title="Plan, deploy and complete any project or task"
      content={(
        <React.Fragment>
          <p>
            Spaces help you organize work to suit your specific needs.
            You can include templates for repetitive events, invite team members,
            define privacy settings, and customize them with extensions,
            further enhancing its functionality.
          </p>
          <p>
            Add lists, tasks, and comments to keep everyone in the loop,
            working towards the same goals.
          </p>
        </React.Fragment>
      )}
      imageName="home-project-tasks.png"
      linkUrl={constants.URLS.HELP_ARTICLE_CREATE_PROJECT}
    />
  )
}, {
  title: 'Notes',
  content: (
    <TabContent
      title="Keep your ideas in one place, always at hand."
      content={(
        <React.Fragment>
          <p>
            Notes help you capture thoughts, reminders, and to-do lists
            on the go without the need for a pen and paper.
          </p>
          <p>
            Notes can include images, videos, links, and files to create a clear,
            easy-to-follow workflow accessible from any device.
          </p>
        </React.Fragment>
      )}
      imageName="home-notes.png"
      linkUrl={constants.URLS.HELP_ARTICLE_NOTES}
    />
  )
}, {
  title: 'Files',
  content: (
    <TabContent
      title="Manage your resources in one central location"
      content={(
        <React.Fragment>
          <p>
            The Google Drive extension helps you to access all your files stored in
            Google Drive directly within Workast to add them to your tasks, subtasks, or comments.
          </p>
          <p>
            You can also upload files directly from your computer,
            ensuring all relevant files are stored in one place.
          </p>
        </React.Fragment>
      )}
      imageName="home-files.png"
      linkUrl={constants.URLS.HELP_ARTICLE_FILE_TO_TASK}
    />
  )
}, {
  title: 'Forms',
  content: (
    <TabContent
      title="Collect essential data effortlessly"
      content={(
        <React.Fragment>
          <p>
            Forms help you create custom questionnaires to collect feedback,
            deploy surveys, and track incidents.
            The intuitive form builder allows you to customize fields,
            add instructions, and set validation rules and privacy settings.
            Once the form is ready, you can share it via a link.
          </p>
          <p>
            Team members and clients can fill out the form on any device,
            and their responses will be instantly organized in a centralized database.
          </p>
        </React.Fragment>
      )}
      imageName="home-forms.png"
      linkUrl={constants.URLS.HELP_ARTICLE_FORMS}
    />
  )
}, {
  title: 'Meetings',
  content: (
    <TabContent
      title="Maximize productivity from scheduling to execution"
      content={(
        <React.Fragment>
          <p>
            Meetings help you collaborate seamlessly with team members.
            Include dates, times, locations, and email reminders to attendees.
            Set an agenda, take notes, and track the process in real time.
          </p>
          <p>
            Afterward, access a comprehensive outline of the key points,
            action items, and decisions to assign tasks directly
            from the meeting&apos;s summary page.
          </p>
        </React.Fragment>
      )}
      imageName="home-meetings.png"
      linkUrl={constants.URLS.HELP_ARTICLE_MOBILE_APP}
    />
  )
}];

const WhatMattersMostSection = () => (
  <WhatMattersMostSectionWrapper>
    <SectionTitle mb="60px">
      What matters most in one place
    </SectionTitle>
    <Container>
      <TabGroup
        items={tabItems}
      />
    </Container>
  </WhatMattersMostSectionWrapper>
);


/*------------------------------------------------*/
/* PROP TYPES
/*------------------------------------------------*/
WhatMattersMostSection.defaultProps = {
};
WhatMattersMostSection.propTypes = {
};

/*------------------------------------------------*/
/* EXPORTS
/*------------------------------------------------*/
export default WhatMattersMostSection;
