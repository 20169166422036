/*------------------------------------------------*/
/* LIBRARIES
/*------------------------------------------------*/
import styled from 'styled-components';
/*------------------------------------------------*/
/* INTERNAL DEPENDENCIES
/*------------------------------------------------*/
// Styles
import MediaBreakDown from '../../../styles/MediaBreakDown';


const TabContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 0 60px;

  .footer-wrapper {
    display: flex;
    align-items: center;
    margin: 30px 0 0 0;

    .text-content {
      margin: 0 10px 0 0;

      p {
        margin: 0;
      }
    }
  }

  ${MediaBreakDown.lg`
    margin: 0 0 40px 0;

    .footer-wrapper {
      flex-direction: column;
      align-items: start;

      .text-content {
        margin: 0 10px 20px 0;
      }
    }
  `}
`;
TabContentWrapper.displayName = 'TabContentWrapper';


/*------------------------------------------------*/
/* EXPORTS
/*------------------------------------------------*/
export default TabContentWrapper;
