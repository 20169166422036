/*------------------------------------------------*/
/* LIBRARIES
/*------------------------------------------------*/
import React from 'react';
import PropTypes from 'prop-types';
/*------------------------------------------------*/
/* INTERNAL DEPENDENCIES
/*------------------------------------------------*/
// Assets
import ArrowRightIcon from '../../../static/images/arrow-right.png';


const HeaderItem = (props) => {
  /* PROPS ------------------------------------------------*/
  const {
    id,
    selected,
    title,
    onClick,
  } = props;

  /* METHODS ------------------------------------------------*/
  const handleItemClick = React.useCallback(() => {
    onClick(id);
  }, []);


  /* RENDER ------------------------------------------------*/
  return (
    <button
      type="button"
      className={`tab-header-item${selected ? ' selected' : ''}`}
      onClick={handleItemClick}
    >
      {title}
      <img
        className="arrow"
        alt="Arrow"
        src={ArrowRightIcon}
      />
    </button>
  );
};


/*------------------------------------------------*/
/* PROP TYPES
/*------------------------------------------------*/
HeaderItem.defaultProps = {
  // Optional props:
  selected: false,
  title: '',
};
HeaderItem.propTypes = {
  // Required props:
  id: PropTypes.string.isRequired,
  // Optional props:
  selected: PropTypes.bool,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  // Required methods:
  onClick: PropTypes.func.isRequired,
};

/*------------------------------------------------*/
/* EXPORTS
/*------------------------------------------------*/
export default HeaderItem;
