/*------------------------------------------------*/
/* LIBRARIES
/*------------------------------------------------*/
import React from 'react';
import PropTypes from 'prop-types';
/*------------------------------------------------*/
/* INTERNAL DEPENDENCIES
/*------------------------------------------------*/
// Components
import Image from '../../Image';
import CallToAction from '../../CallToAction';
// Style
import TabContentWrapper from './TabContent.style';


const TabContent = (props) => {
  /* PROPS ------------------------------------------------*/
  const {
    title,
    content,
    imageName,
    linkUrl,
  } = props;


  /* RENDER ------------------------------------------------*/
  return (
    <TabContentWrapper>
      <div className="image-wrapper">
        <Image
          name={imageName}
          alt={title}
        />
      </div>
      <div className="footer-wrapper">
        <div className="text-content">
          {content}
        </div>
        <div>
          <CallToAction
            text="Learn more"
            link={linkUrl}
            type="ghost"
          />
        </div>
      </div>
    </TabContentWrapper>
  );
};


/*------------------------------------------------*/
/* PROP TYPES
/*------------------------------------------------*/
TabContent.defaultProps = {};
TabContent.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.element.isRequired,
  imageName: PropTypes.string.isRequired,
  linkUrl: PropTypes.string.isRequired,
};

/*------------------------------------------------*/
/* EXPORTS
/*------------------------------------------------*/
export default TabContent;
