/*------------------------------------------------*/
/* LIBRARIES
/*------------------------------------------------*/
import styled from 'styled-components';
/*------------------------------------------------*/
/* INTERNAL DEPENDENCIES
/*------------------------------------------------*/
// Styles
import MediaBreakDown from '../../../styles/MediaBreakDown';


const TabContentWrapper = styled.div`
  display: flex;
  padding: 60px 40px;

  .left-column {
    width: 35%;
  }
  .right-column {
    width: 65%;
  }

  .left-column {
    margin: 0 20px 0 0;

    .text-content {
      text-align: left;
      max-width: 400px;
      margin: auto;

      .title {
        margin-bottom: 1.4rem;
        line-height: 1.4em;
      }
    }
    .content {
      margin: 0 0 40px 0;
    }
  }

  ${MediaBreakDown.md`
    flex-direction: column;
    padding: 40px 20px;

    .left-column,
    .right-column {
      width: initial;
    }
    .left-column {
      order: 2;
      width: initial;
      margin: 40px 0 0 0;
      
      .text-content {
        max-width: initial;
      }
    }
    .right-column {
      order: 1;
      margin: 0;
      display: block;
    }
  `}
`;
TabContentWrapper.displayName = 'TabContentWrapper';


/*------------------------------------------------*/
/* EXPORTS
/*------------------------------------------------*/
export default TabContentWrapper;
