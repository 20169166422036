/*------------------------------------------------*/
/* LIBRARIES
/*------------------------------------------------*/
import styled from 'styled-components';
/*------------------------------------------------*/
/* INTERNAL DEPENDENCIES
/*------------------------------------------------*/
// Styles
import Container from '../../Container';
import MediaBreakDown from '../../../styles/MediaBreakDown';
import {
  BodyTextBig,
} from '../../Global';


const WorkToSuitSectionWrapper = styled.div`
  text-align: center;
  margin: 0 0 100px 0;

  ${BodyTextBig} {
    max-width: 700px;
    margin: 0 auto 60px auto;
  }
  ${MediaBreakDown.lg`
    ${Container} {
      padding: 0;
    }
  `}
`;
WorkToSuitSectionWrapper.displayName = 'WorkToSuitSectionWrapper';

/*------------------------------------------------*/
/* EXPORTS
/*------------------------------------------------*/
export default WorkToSuitSectionWrapper;
