/*------------------------------------------------*/
/* LIBRARIES
/*------------------------------------------------*/
import styled from 'styled-components';
/*------------------------------------------------*/
/* INTERNAL DEPENDENCIES
/*------------------------------------------------*/
// Styles
import MediaBreakDown from '../../styles/MediaBreakDown';
import theme from '../../styles/theme';


const AccordionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: ${theme.body.gray};
  padding: 40px 50px;

  .tab-header-wrapper {
    display: flex;
    flex-direction: column;

    .tab-header-title {
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      margin: 0 0 40px 0;
    }
    .buttons-wrapper {
      display: flex;
      flex-direction: column;

      .tab-header-item {
        background: none;
        border: none;
        outline: none;
        border-radius: 20px;
        padding: 16px 0;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        color: ${theme.textColor.suvaGray};
        cursor: pointer;
        white-space: nowrap;
        transition: all .25s ease-in-out;
        width: 100%;
        display: flex;
        justify-content: space-between;
  
        &.selected {
          color: ${theme.textColor.blueText};
         
          .arrow {
            transform: rotate(90deg);
          }
        }

        .arrow {
          margin: 0;
          transition: all .25s linear;
        }
      }
    }
  }
  .tab-content-wrapper {
    background: ${theme.body.gray};
    margin: 20px 0 0 0;
    border-radius: 0 20px 20px 20px;
    transition: all .3s linear;
    max-height: 0;
    overflow: hidden;

    &.visible {
      max-height: 700px;
    }
  }

  ${MediaBreakDown.md`
    padding: 40px 20px;

    .tab-header-item {
      transition: 0.5s all;
    }
    .tab-content-wrapper {
      border-radius: 0;
    }
  `}
`;
AccordionWrapper.displayName = 'AccordionWrapper';


/*------------------------------------------------*/
/* EXPORTS
/*------------------------------------------------*/
export default AccordionWrapper;
