/*------------------------------------------------*/
/* LIBRARIES
/*------------------------------------------------*/
import styled from 'styled-components';
/*------------------------------------------------*/
/* INTERNAL DEPENDENCIES
/*------------------------------------------------*/
// Styles
import {
  SectionTitle,
} from '../../Global';
import Container from '../../Container';
import MediaBreakDown from '../../../styles/MediaBreakDown';


const EmpowerYourTeamSectionWrapper = styled.div`
  margin: 0 0 100px 0;
  
  ${SectionTitle} {
    text-align: center;
    max-width: 600px;
  }

  .sidetab-wrapper {
    display: initial;
  }
  .accordion-wrapper {
    display: none;
  }

  ${MediaBreakDown.lg`
    ${Container} {
      padding: 0;
    }

    .sidetab-wrapper {
      display: none;
    }
    .accordion-wrapper {
      display: initial;
    }
  `}
`;
EmpowerYourTeamSectionWrapper.displayName = 'EmpowerYourTeamSectionWrapper';

const TabButton = styled.div`
  display: flex;
  align-items: center;

  img {
    width: 30px;
    margin: 0 12px 0 0;
  }
`;
TabButton.displayName = 'TabButton';


/*------------------------------------------------*/
/* EXPORTS
/*------------------------------------------------*/
export default EmpowerYourTeamSectionWrapper;
export {
  TabButton,
};
