/*------------------------------------------------*/
/* LIBRARIES
/*------------------------------------------------*/
import styled from 'styled-components';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
/*------------------------------------------------*/
/* INTERNAL DEPENDENCIES
/*------------------------------------------------*/
// Styles
import MediaBreakDown from '../../styles/MediaBreakDown';
import theme from '../../styles/theme';


const TabGroupWrapper = styled.div`
  .tab-header-wrapper {
    display: flex;

    .tab-header-item {
      background: none;
      border: none;
      outline: none;
      border-radius: 20px 20px 0 0;
      padding: 14px 18px 10px 18px;
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      text-align: center;
      color: ${theme.textColor.suvaGray};
      cursor: pointer;
      white-space: nowrap;

      &.selected {
        background: ${theme.body.gray};
      }
    }
  }
  .tab-content-wrapper {
    background: ${theme.body.gray};
    border-radius: 0 20px 20px 20px;
  }

  ${MediaBreakDown.md`
    .tab-header-item {
      transition: 0.5s all;
      transform: translateX(${props => _get(props, 'headerTranslate', '0')}px)
    }
    .tab-content-wrapper {
      border-radius: 0;
    }
  `}
`;
TabGroupWrapper.displayName = 'TabGroupWrapper';
TabGroupWrapper.defaultProps = {
  headerTranslate: 0,
};
TabGroupWrapper.propTypes = {
  headerTranslate: PropTypes.number,
};


/*------------------------------------------------*/
/* EXPORTS
/*------------------------------------------------*/
export default TabGroupWrapper;
