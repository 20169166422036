/*------------------------------------------------*/
/* LIBRARIES
/*------------------------------------------------*/
import styled from 'styled-components';
/*------------------------------------------------*/
/* INTERNAL DEPENDENCIES
/*------------------------------------------------*/
// Styles
import MediaBreakDown from '../../../styles/MediaBreakDown';
// Components
import {
  BodyTextBig,
} from '../../Global';


const CenteredSection = styled.div`
  padding: 100px 0 0;
  text-align: center;

  ${BodyTextBig} {
    margin-bottom: 40px;
  }

  .items-wrapper-large,
  .items-wrapper-small {
    a.item {
      img {
        height: 100px;
        width: 100px;
        max-width: 100px;
        min-width: 100px;
        border-radius: 6px;
        box-shadow: 4px 4px 4px rgba(0,0,0,0.15);
        
      }
    }
  }
  .items-wrapper-large {
    display: flex;
    justify-content: space-around;
    max-width: 980px;

    a.item {
      img {
        transition: all ease-in-out 0.3s;

        &:hover {
          transform: translateY(-4px);
          box-shadow: 4px 4px 10px rgba(0,0,0,0.15);
        }
      }
    }
  }
  .items-wrapper-small {
    display: none;

    a.item {
      img {
        margin: auto;
      }
    }
  }

  ${MediaBreakDown.lg`
    .items-wrapper-large {
      display: none;
    }
    .items-wrapper-small {
      display: initial;
    }
  `}
  ${MediaBreakDown.sm`
    padding: 60px 0 80px;
  `}
`;

const SliderWrap = styled.div`
  position: relative;
  width: 100%;
  max-width: 1200px;
  display: none;

  ${MediaBreakDown.lg`
    display: initial;
  `}
`;

/*------------------------------------------------*/
/* EXPORTS
/*------------------------------------------------*/
export {
  CenteredSection,
  SliderWrap,
};
