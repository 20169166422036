/*------------------------------------------------*/
/* LIBRARIES
/*------------------------------------------------*/
import React from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import _toNumber from 'lodash/toNumber';
/*------------------------------------------------*/
/* INTERNAL DEPENDENCIES
/*------------------------------------------------*/
// Style
import TabGroupWrapper from './index.style';


const TabGroup = (props) => {
  /* PROPS ------------------------------------------------*/
  const {
    items,
  } = props;

  /* USE STATE ------------------------------------------------*/
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  /* METHODS ------------------------------------------------*/
  const handleItemClick = React.useCallback((event) => {
    const {
      nativeEvent: {
        target: {
          dataset: {
            index,
          },
        },
      },
    } = event;

    setSelectedIndex(_toNumber(index));
  }, []);


  /* RENDER ------------------------------------------------*/
  if (items.length === 0) {
    return null;
  }

  return (
    <TabGroupWrapper
      headerTranslate={selectedIndex * -20}
    >
      <div className="tab-header-wrapper">
        {items.map((item, itemIndex) => (
          <button
            type="button"
            key={_get(item, 'title', '')}
            data-index={itemIndex}
            className={`tab-header-item${itemIndex === selectedIndex ? ' selected' : ''}`}
            onClick={handleItemClick}
          >
            {_get(item, 'title', '')}
          </button>
        ))}
      </div>
      <div className="tab-content-wrapper">
        {items[selectedIndex].content}
      </div>
    </TabGroupWrapper>
  );
};


/*------------------------------------------------*/
/* PROP TYPES
/*------------------------------------------------*/
TabGroup.defaultProps = {
  items: [],
};
TabGroup.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      content: PropTypes.element,
    })
  ),
};

/*------------------------------------------------*/
/* EXPORTS
/*------------------------------------------------*/
export default TabGroup;
