/*------------------------------------------------*/
/* LIBRARIES
/*------------------------------------------------*/
import styled from 'styled-components';
/*------------------------------------------------*/
/* INTERNAL DEPENDENCIES
/*------------------------------------------------*/
// Styles
import MediaBreakDown from '../../styles/MediaBreakDown';
import theme from '../../styles/theme';


const SideTabGroupWrapper = styled.div`
  display: flex;
  background: ${theme.body.gray};
  padding: 40px;
  border-radius: 20px;

  .tab-header-wrapper {
    display: flex;
    flex-direction: column;
    min-width: 370px;
    max-width: 370px;

    .tab-header-title {
      font-weight: 400;
      font-size: 28px;
      line-height: 34px;
      margin: 0 0 50px 0;
    }
    .buttons-wrapper {
      display: flex;
      flex-direction: column;

      .tab-header-item {
        background: none;
        border: none;
        outline: none;
        border-radius: 20px;
        padding: 16px 18px;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        color: ${theme.textColor.suvaGray};
        cursor: pointer;
        white-space: nowrap;
        transition: all .25s ease-in-out;
  
        &.selected {
          background: ${theme.body.dark};
          color: ${theme.textColor.blueText};
        }
      }
    }
  }
  .tab-content-wrapper {
    background: ${theme.body.gray};
    border-radius: 0 20px 20px 20px;
  }

  ${MediaBreakDown.md`
    .tab-header-item {
      transition: 0.5s all;
    }
    .tab-content-wrapper {
      border-radius: 0;
    }
  `}
`;
SideTabGroupWrapper.displayName = 'SideTabGroupWrapper';


/*------------------------------------------------*/
/* EXPORTS
/*------------------------------------------------*/
export default SideTabGroupWrapper;
