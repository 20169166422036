/*------------------------------------------------*/
/* LIBRARIES
/*------------------------------------------------*/
import React from 'react';
/*------------------------------------------------*/
/* INTERNAL DEPENDENCIES
/*------------------------------------------------*/
// Components
import {
  BodyTextBig,
  SectionTitle,
} from '../../Global';
import Container from '../../Container';
import TabGroup from '../../TabGroup';
import TabContent from '../WhatMattersMostSection/TabContent';
// Style
import WorkToSuitSectionWrapper from './index.style';
// Constans
import constants from '../../../constants';


const tabItems = [{
  title: 'List',
  content: (
    <TabContent
      title="Build personalized lists for any project"
      content={(
        <React.Fragment>
          <p>
            Use Custom fields to gain the flexibility you need to create the perfect workflow.
          </p>
        </React.Fragment>
      )}
      imageName="work-to-suit-list.png"
      linkUrl={constants.URLS.HELP_ARTICLE_CUSTOM_FIELDS}
    />
  )
}, {
  title: 'Board',
  content: (
    <TabContent
      title="Organize your work in an intuitive and visual interface"
      content={(
        <React.Fragment>
          <p>
            Drag and drop tasks quickly between columns,
            create new tasks, and assign them to team members.
          </p>
        </React.Fragment>
      )}
      imageName="work-to-suit-board.png"
      linkUrl={constants.URLS.HELP_ARTICLE_BOARD}
    />
  )
}, {
  title: 'Calendar',
  content: (
    <TabContent
      title="Stay productive and on schedule"
      content={(
        <React.Fragment>
          <p>
            Make your workdays easier with reminders, notifications, and due dates.
          </p>
        </React.Fragment>
      )}
      imageName="work-to-suit-calendar.png"
      linkUrl={constants.URLS.HELP_ARTICLE_CALENDAR}
    />
  )
}, {
  title: 'Timeline',
  content: (
    <TabContent
      title="Track progress over time"
      content={(
        <React.Fragment>
          <p>
            Gain a clear overview of all your projects and adjust
            your tasks&apos; timing and duration to ensure you meet all deadlines.
          </p>
        </React.Fragment>
      )}
      imageName="work-to-suit-timeline.png"
      linkUrl={constants.URLS.HELP_ARTICLE_TIMELINE}
    />
  )
}];

const WorkToSuitSection = () => (
  <WorkToSuitSectionWrapper>
    <SectionTitle mb="30px">
      Work to suit your style
    </SectionTitle>
    <BodyTextBig>
      See the progress of each project, identify dependencies,
      and spot potential bottlenecks before they become a problem.
    </BodyTextBig>
    <Container>
      <TabGroup
        items={tabItems}
      />
    </Container>
  </WorkToSuitSectionWrapper>
);


/*------------------------------------------------*/
/* PROP TYPES
/*------------------------------------------------*/
WorkToSuitSection.defaultProps = {
};
WorkToSuitSection.propTypes = {
};

/*------------------------------------------------*/
/* EXPORTS
/*------------------------------------------------*/
export default WorkToSuitSection;
