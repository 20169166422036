/*------------------------------------------------*/
/* LIBRARIES
/*------------------------------------------------*/
import React from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import Slider from 'react-slick';
/*------------------------------------------------*/
/* INTERNAL DEPENDENCIES
/*------------------------------------------------*/
// Components
import {
  BodyTextBig,
  SectionTitle,
} from '../../Global';
import Container from '../../Container';
// Helpers
import urlHelper from '../../../utils/url';
// Style
import {
  CenteredSection,
} from './index.style';
import SliderWrap, { SliderCard } from '../../Slider/index.style';


const settings = {
  autoplay: true,
  autoplaySpeed: 5000,
  centerMode: true,
  centerPadding: '40px',
  dots: true,
  infinite: true,
  pauseOnHover: true,
  slidesToShow: 5,
  speed: 500,
  swipeToSlide: true,
  responsive: [{
    breakpoint: 700,
    settings: {
      slidesToShow: 3,
      centerPadding: '10px',
    }
  }, {
    breakpoint: 350,
    settings: {
      slidesToShow: 2,
      centerPadding: '10px',
    }
  }]
};


const WorkManagementPlatformSection = (props) => {
  const {
    items,
  } = props;


  return (
    <CenteredSection>
      <Container>
        <SectionTitle mb="10px">
          The Work Management Platform for your Business
        </SectionTitle>
        <BodyTextBig>
          Experience a new level of teamwork with Workast
        </BodyTextBig>
      </Container>
      <Container className="items-wrapper-large">
        {items.map(item => (
          <a
            key={_get(item, 'slug', '')}
            className="item"
            href={urlHelper.getSolutionArticle(_get(item, 'slug', ''))}
            target="_blank"
            rel="noreferrer"
          >
            <img
              alt={_get(item, 'icon.title', '')}
              src={_get(item, 'icon.url', '')}
            />
          </a>
        ))}
      </Container>
      <SliderWrap className="items-wrapper-small">
        <Slider {...settings}>
          {items.map(item => (
            <SliderCard key={_get(item, 'slug', '')}>
              <a
                key={_get(item, 'slug', '')}
                className="item"
                href={urlHelper.getSolutionArticle(_get(item, 'slug', ''))}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  alt={_get(item, 'icon.title', '')}
                  src={_get(item, 'icon.url', '')}
                />
              </a>
            </SliderCard>
          ))}
        </Slider>
      </SliderWrap>
    </CenteredSection>
  );
};


/*------------------------------------------------*/
/* PROP TYPES
/*------------------------------------------------*/
WorkManagementPlatformSection.defaultProps = {
  items: [],
};
WorkManagementPlatformSection.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string,
      icon: PropTypes.shape({
        title: PropTypes.string,
        url: PropTypes.string,
      }),
    })
  ),
};

/*------------------------------------------------*/
/* EXPORTS
/*------------------------------------------------*/
export default WorkManagementPlatformSection;
