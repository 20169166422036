/*------------------------------------------------*/
/* LIBRARIES
/*------------------------------------------------*/
import React from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
/*------------------------------------------------*/
/* INTERNAL DEPENDENCIES
/*------------------------------------------------*/
// Components
import HeaderItem from './HeaderItem';
// Style
import AccordionWrapper from './index.style';


const Accordion = (props) => {
  /* PROPS ------------------------------------------------*/
  const {
    title,
    items,
    defaultValues,
  } = props;

  /* USE STATE ------------------------------------------------*/
  const [selectedIds, setSelectedIds] = React.useState({});

  React.useEffect(() => {
    if (Array.isArray(defaultValues) && defaultValues.length > 0) {
      const newSelectedIds = {};
      
      for (let index = 0; index < defaultValues.length; index += 1) {
        newSelectedIds[defaultValues[index]] = true;
      }

      setSelectedIds(newSelectedIds);
    }
  }, []);
  /* METHODS ------------------------------------------------*/
  const handleItemClick = React.useCallback((id) => {
    setSelectedIds(oldSelectedIds => ({
      ...oldSelectedIds,
      [id]: !oldSelectedIds[id],
    }));
  }, []);


  /* RENDER ------------------------------------------------*/
  if (items.length === 0) {
    return null;
  }

  return (
    <AccordionWrapper>
      <div className="tab-header-wrapper">
        <h3 className="tab-header-title">{title}</h3>
        <div className="buttons-wrapper">
          {items.map(item => (
            <div key={_get(item, 'id', '')}>
              <HeaderItem
                id={_get(item, 'id', '')}
                selected={!!selectedIds[_get(item, 'id', '')]}
                title={_get(item, 'title', '')}
                onClick={handleItemClick}
              />
              <div className={`tab-content-wrapper${selectedIds[_get(item, 'id', '')] ? ' visible' : ''}`}>
                {item.content}
              </div>
            </div>
          ))}
        </div>
      </div>
    </AccordionWrapper>
  );
};


/*------------------------------------------------*/
/* PROP TYPES
/*------------------------------------------------*/
Accordion.defaultProps = {
  items: [],
  defaultValues: [],
};
Accordion.propTypes = {
  // Required props:
  title: PropTypes.string.isRequired,
  // Optional props:
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
      ]),
      content: PropTypes.element,
    })
  ),
  defaultValues: PropTypes.arrayOf(PropTypes.string)
};

/*------------------------------------------------*/
/* EXPORTS
/*------------------------------------------------*/
export default Accordion;
