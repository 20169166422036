/*------------------------------------------------*/
/* LIBRARIES
/*------------------------------------------------*/
import React from 'react';
/*------------------------------------------------*/
/* INTERNAL DEPENDENCIES
/*------------------------------------------------*/
// Components
import {
  SectionTitle,
} from '../../Global';
import Container from '../../Container';
import SideTabGroup from '../../SideTabGroup';
import Accordion from '../../Accordion';
import TabContent from './TabContent';
// Style
import EmpowerYourTeamSectionWrapper, {
  TabButton,
} from './index.style';
// Assets
import SlackIcon from '../../../../static/images/icon-slack.png';
import WebexIcon from '../../../../static/images/icon-webex.png';
import GmailIcon from '../../../../static/images/icon-gmail.png';
import CalendarIcon from '../../../../static/images/icon-calendar.png';
import ZapierIcon from '../../../../static/images/icon-zapier.png';


const data = [{
  id: 'slack',
  title: (
    <TabButton>
      <img src={SlackIcon} alt="Slack" />
      Slack
    </TabButton>
  ),
  content: (
    <TabContent
      title="Slack"
      content={(
        <p>
          Create tasks in a channel, turn a Slack message into a task,
          or create a task from anywhere in Slack using the Workast shortcut.
        </p>
      )}
      imageName="empower-slack.png"
      linkUrl="https://www.workast.com/slack/"
    />
  ),
}, {
  id: 'webex',
  title: (
    <TabButton>
      <img src={WebexIcon} alt="Webex" />
      Webex
    </TabButton>
  ),
  content: (
    <TabContent
      title="Webex"
      content={(
        <p>
          Set up projects, create tasks, capture to-dos,
          set due dates and assign work right from within Webex.
        </p>
      )}
      imageName="empower-webex.png"
      linkUrl="https://www.workast.com/webex/"
    />
  ),
}, {
  id: 'email',
  title: (
    <TabButton>
      <img src={GmailIcon} alt="Email" />
      Email
    </TabButton>
  ),
  content: (
    <TabContent
      title="Email"
      content={(
        <p>
          Emails get forgotten about.
          Turn emails into tasks with a quick click of a button so every email gets taken care of.
        </p>
      )}
      imageName="empower-gmail.png"
      linkUrl="https://www.workast.com/extensions/email/"
    />
  ),
}, {
  id: 'calendar',
  title: (
    <TabButton>
      <img src={CalendarIcon} alt="Calendar" />
      Calendar
    </TabButton>
  ),
  content: (
    <TabContent
      title="Calendar"
      content={(
        <p>
          Plan out your day by connecting your work calendar
          to Workast to see all your tasks and due dates at a glance.
        </p>
      )}
      imageName="empower-calendar.png"
      linkUrl="https://www.workast.com/extensions/calendar/"
    />
  ),
}, {
  id: 'zapier',
  title: (
    <TabButton>
      <img src={ZapierIcon} alt="Zapier" />
      Zapier
    </TabButton>
  ),
  content: (
    <TabContent
      title="Zapier"
      content={(
        <p>
          Connect Workast with over 2000 apps to keep all your work in one place.
          Never let work slip through the cracks.
        </p>
      )}
      imageName="empower-zapier.png"
      linkUrl="https://www.workast.com/extensions/zapier/"
    />
  ),
}];


const EmpowerYourTeamSection = () => (
  <EmpowerYourTeamSectionWrapper>
    <Container>
      <SectionTitle mb="40px">
        Empower your team with seamless integrations
      </SectionTitle>
      <div className="sidetab-wrapper">
        <SideTabGroup
          title="Reduce context switching and increase team adoption"
          items={data}
        />
      </div>
      <div className="accordion-wrapper">
        <Accordion
          title="Reduce context switching and increase team adoption"
          items={data}
          defaultValues={['slack']}
        />
      </div>
    </Container>
  </EmpowerYourTeamSectionWrapper>
);


/*------------------------------------------------*/
/* PROP TYPES
/*------------------------------------------------*/
EmpowerYourTeamSection.defaultProps = {};
EmpowerYourTeamSection.propTypes = {};

/*------------------------------------------------*/
/* EXPORTS
/*------------------------------------------------*/
export default EmpowerYourTeamSection;
