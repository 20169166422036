/*------------------------------------------------*/
/* LIBRARIES
/*------------------------------------------------*/
import styled from 'styled-components';
/*------------------------------------------------*/
/* INTERNAL DEPENDENCIES
/*------------------------------------------------*/
// Styles
import Container from '../../Container';
import MediaBreakDown from '../../../styles/MediaBreakDown';


const WhatMattersMostSectionWrapper = styled.div`
  text-align: center;
  margin: 0 0 100px 0;

  ${MediaBreakDown.lg`
    ${Container} {
      padding: 0;
    }
  `}
`;
WhatMattersMostSectionWrapper.displayName = 'WhatMattersMostSectionWrapper';

/*------------------------------------------------*/
/* EXPORTS
/*------------------------------------------------*/
export default WhatMattersMostSectionWrapper;
